import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import { Field, RichText, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type PointFormItemProps = ComponentProps & {
  fields: {
    numberText: Field<string>;
    text: Field<string>;
  };
};

const PointFormItem = (props: PointFormItemProps): JSX.Element => {
  const isNumber = props.params?.isNumber;
  return (
    <Stack
      className={`${props.params?.styles}`}
      direction="row"
      alignItems="center"
      sx={{ padding: `${EPixel.px8} ${EPixel.px12}` }}
    >
      {isNumber ? (
        <Box
          sx={{
            fontSize: EPixel.px16,
            lineHeight: EPixel.px24,
            fontWeight: 'bolder',
            color: '#702B91',
            mr: EPixel.px8,
          }}
        >
          <Text field={props.fields?.numberText} />
        </Box>
      ) : (
        <Box
          sx={{
            width: EPixel.px8,
            height: EPixel.px8,
            backgroundColor: '#702b91',
            borderRadius: '50%',
            mr: EPixel.px8,
          }}
        ></Box>
      )}
      <RichText field={props.fields?.text} />
    </Stack>
  );
};

export default withDatasourceCheck()<PointFormItemProps>(PointFormItem);
