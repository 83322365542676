import {
  Box,
  Button,
  ButtonVariant,
  EPixel,
  Font,
  useMobileQuery,
} from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Field,
  ImageField,
  Link,
  LinkFieldValue,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type CardImageProps = ComponentProps & {
  fields: {
    image: ImageField;
    imageIcon: ImageField;
    title: Field<string>;
    content: Field<string>;
    buttonText: Field<string>;
    link: LinkFieldValue;
  };
};

const StyledImageBox = styled(Box)(({}) => ({
  height: '267px',
  overflow: 'hidden',
  '& img': {
    objectFit: 'cover',
    maxWidth: '100%',
    width: '100%',
    objectPosition: 'center',
  },
}));

const CardImage = (props: CardImageProps): JSX.Element => {
  const hasImageIcon = props.params?.hasImageIcon;
  const isMobile = useMobileQuery();
  return (
    <Stack
      className={`${props.params?.styles}`}
      sx={{
        borderRadius: EPixel.px16,
        border: '1px solid #D6D6D8',
        backgroundColor: '#ffffff',
        overflow: 'hidden',
      }}
    >
      {!isMobile && (
        <StyledImageBox>
          <JssImage field={props.fields?.image} width="382" height="267" />
        </StyledImageBox>
      )}

      <Stack
        sx={{ padding: EPixel.px24, flex: 1 }}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          {hasImageIcon && (
            <Box
              sx={{
                marginBottom: EPixel.px16,
                '& > img': { height: 'initial', minHeight: '36px' },
              }}
            >
              <JssImage field={props.fields?.imageIcon} height="60" />
            </Box>
          )}
          <Font
            sx={{ marginBottom: EPixel.px16, fontFamily: 'var(--font-pluto-sans-medium)' }}
            fontSize={isMobile ? '20px' : '26px'}
            lineHeight={isMobile ? '28px' : '34px'}
            fontWeight="500"
          >
            <Text field={props.fields?.title} />
          </Font>
          <Box sx={{ marginBottom: EPixel.px16 }}>
            <RichText field={props.fields?.content} />
          </Box>
        </Box>
        <Link field={props.fields?.link}>
          <Button variant={ButtonVariant.Secondary}>
            <Text field={props.fields?.buttonText} />
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default withDatasourceCheck()<CardImageProps>(CardImage);
