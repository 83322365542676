import { Box, EFont, EPixel, Font, constants } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import {
  Field,
  ImageField,
  Link,
  LinkFieldValue,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type CardFrameProps = ComponentProps & {
  fields: {
    title: Field<string>;
    imageIcon: ImageField;
    content: Field<string>;
    link: LinkFieldValue;
  };
};

const CardFrame = (props: CardFrameProps): JSX.Element => {
  return (
    <Link
      className={`${props.params?.styles}`}
      style={{ textDecoration: 'none' }}
      field={props.fields?.link}
    >
      <Stack
        direction="row"
        sx={{
          height: '100%',
          padding: EPixel.px16,
          borderRadius: EPixel.px16,
          border: '1px solid #D6D6D8',
          overflow: 'hidden',
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: EPixel.px64,
            height: EPixel.px64,
            borderRadius: '50%',
            backgroundColor: '#F6F4F9',
            '& img': {
              maxWidth: '100%',
            },
          }}
        >
          <JssImage width={40} field={props.fields?.imageIcon} />
        </Stack>
        <Box sx={{ flex: 1, ml: EPixel.px16, color: '#000' }}>
          <Font variant={EFont.h2} fontWeight="bolder">
            <Text field={props.fields?.title} />
          </Font>
          <Font variant={EFont.p1} sx={{ mt: EPixel.px16, fontFamily: constants.varFontPlutoSans }}>
            <RichText field={props.fields?.content} />
          </Font>
        </Box>
      </Stack>
    </Link>
  );
};

export default withDatasourceCheck()<CardFrameProps>(CardFrame);
